// https://app.swaggerhub.com/apis/TerraMagna/iam-service/2.1#/Roles
export enum Role {
  MANAGER = "MANAGER",
  ANALYST = "ANALYST",
  OPERATION_REQUESTER = "OPERATION_REQUESTER",
  OPERATION_REGISTER = "OPERATION_REGISTER",
  SALESMAN = "SALESMAN",
  SALES_ADMIN = "SALES_ADMIN",
  CARTOGRAPHY_REVIEWER = "CARTOGRAPHY_REVIEWER",
  CARTOGRAPHY_ANALYST = "CARTOGRAPHY_ANALYST",
  CREDIT_ANALYST = "CREDIT_ANALYST",
  CREDIT_MANAGER = "CREDIT_MANAGER",
  CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS",
  CUSTOMER_SUCCESS_REGISTER = "CUSTOMER_SUCCESS_REGISTER",
  FARMER_ANALYSIS_MANAGER = "FARMER_ANALYSIS_MANAGER",
}

export enum RoleTranslate {
  MANAGER = "Gestor de usuários",
  ANALYST = "Analista de cobrança",
  OPERATION_REQUESTER = "Gestor de operações",
  OPERATION_REGISTER = "Cadastro de operações",
  SALESMAN = "Executivo de vendas",
  SALES_ADMIN = "Gerente de vendas",
  CARTOGRAPHY_REVIEWER = "Revisor de geometria",
  CARTOGRAPHY_ANALYST = "Analista de cartografia",
  CREDIT_ANALYST = "Analista de crédito",
  CREDIT_MANAGER = "Gerente de crédito",
  CUSTOMER_SUCCESS = "Analista de CS",
  CUSTOMER_SUCCESS_REGISTER = "Analista de cadastro de CS",
  FARMER_ANALYSIS_MANAGER = "Gestor de análises do produtor",
}

export enum RoleDescription {
  MANAGER = "Permite adicionar e excluir usuários.",
  ANALYST = "Permite visualizar as operações que estão em monitoramento.",
  OPERATION_REQUESTER = "Permite pedir novas operações.",
  OPERATION_REGISTER = "Permite cadastrar operações no sistema.",
  SALESMAN = "Permite criar novos clientes.",
  SALES_ADMIN = "Permite ativar ou desativar clientes.",
  CARTOGRAPHY_REVIEWER = "Permite pedir revisões de geometrias nas fazendas em monitoramento.",
  CARTOGRAPHY_ANALYST = "Permite realizar tarefas da cartografia.",
  CREDIT_ANALYST = "Permite simular análises de crédito para o produtor e consultar restrições no CPF/CNPJ.",
  CREDIT_MANAGER = "Permite enviar configurações para análises de crédito.",
  CUSTOMER_SUCCESS = "Permite criar clientes, editar operações e análises de crédito",
  CUSTOMER_SUCCESS_REGISTER = "Permite criar clientes e editar operações de monitoramento",
  FARMER_ANALYSIS_MANAGER = "Permite visualizar e criar novas análises do produtor",
}
